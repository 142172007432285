/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getCameraLiveStreamingUrl = /* GraphQL */ `
  query GetCameraLiveStreamingUrl($streamName: String!) {
    getCameraLiveStreamingUrl(streamName: $streamName) {
      url
      error
    }
  }
`;
export const getEccMenu = /* GraphQL */ `
  query GetEccMenu($id: ID!) {
    getEccMenu(id: $id) {
      id
      accountId
      path
      name
      createdAt
      updatedAt
    }
  }
`;
export const listEccMenus = /* GraphQL */ `
  query ListEccMenus(
    $id: ID
    $filter: ModelEccMenuFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEccMenus(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        accountId
        path
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNodeType = /* GraphQL */ `
  query GetNodeType($id: ID!) {
    getNodeType(id: $id) {
      id
      name
      typeIcon
      imagePath
      mapImagePath
      mainDatumIndex
      reversedLabelColor
      permissions
      inquiryChart {
        title
        inquiries {
          id
          title
          description
          image {
            ... on InquiryImageHref {
              href
            }
            ... on InquiryImageS3 {
              s3Key
            }
          }
          options {
            title
            next
          }
        }
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNodeTypes = /* GraphQL */ `
  query ListNodeTypes(
    $id: ID
    $filter: ModelNodeTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNodeTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        typeIcon
        imagePath
        mapImagePath
        mainDatumIndex
        reversedLabelColor
        permissions
        inquiryChart {
          title
          inquiries {
            id
            title
            description
            image {
              ... on InquiryImageHref {
                href
              }
              ... on InquiryImageS3 {
                s3Key
              }
            }
            options {
              title
              next
            }
          }
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTenant = /* GraphQL */ `
  query GetTenant($id: ID!) {
    getTenant(id: $id) {
      id
      name
      permissions
      iotDevices {
        id
        dataPoints
        sendCycle
        deployedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTenants = /* GraphQL */ `
  query ListTenants(
    $id: ID
    $filter: ModelTenantFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listTenants(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        permissions
        iotDevices {
          id
          dataPoints
          sendCycle
          deployedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getWorkHistory = /* GraphQL */ `
  query GetWorkHistory($id: ID!) {
    getWorkHistory(id: $id) {
      id
      time
      nodeId
      workType
      workSummary
      worker
      workNumber
      positionX
      positionY
      positionZ
      createdAt
      updatedAt
    }
  }
`;
export const listWorkHistories = /* GraphQL */ `
  query ListWorkHistories(
    $id: ID
    $filter: ModelWorkHistoryFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWorkHistories(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        time
        nodeId
        workType
        workSummary
        worker
        workNumber
        positionX
        positionY
        positionZ
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEccMenusByAccountId = /* GraphQL */ `
  query ListEccMenusByAccountId(
    $accountId: String
    $sortDirection: ModelSortDirection
    $filter: ModelEccMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEccMenusByAccountId(
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        accountId
        path
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listWorkHistoryByNodeId = /* GraphQL */ `
  query ListWorkHistoryByNodeId(
    $nodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelWorkHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkHistoryByNodeId(
      nodeId: $nodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        nodeId
        workType
        workSummary
        worker
        workNumber
        positionX
        positionY
        positionZ
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listWorkHistoryByNodeIdAndTime = /* GraphQL */ `
  query ListWorkHistoryByNodeIdAndTime(
    $nodeId: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelWorkHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkHistoryByNodeIdAndTime(
      nodeId: $nodeId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        nodeId
        workType
        workSummary
        worker
        workNumber
        positionX
        positionY
        positionZ
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAgvPosition = /* GraphQL */ `
  query GetAgvPosition($id: ID!) {
    getAgvPosition(id: $id) {
      id
      nodeId
      imagePath
      displayName
      floor
      layout
      paperSize
      scale
      revisedPriceX
      revisedPriceY
      theta
      originX
      originY
      type
      datumTypeIds {
        nodeId
        xPositionDatumType
        yPositionDatumType
        floorDatumType
        layoutDatumType
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAgvPositions = /* GraphQL */ `
  query ListAgvPositions(
    $id: ID
    $filter: ModelAgvPositionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAgvPositions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nodeId
        imagePath
        displayName
        floor
        layout
        paperSize
        scale
        revisedPriceX
        revisedPriceY
        theta
        originX
        originY
        type
        datumTypeIds {
          nodeId
          xPositionDatumType
          yPositionDatumType
          floorDatumType
          layoutDatumType
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAgvPositionByNodeId = /* GraphQL */ `
  query ListAgvPositionByNodeId(
    $nodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelAgvPositionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgvPositionByNodeId(
      nodeId: $nodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nodeId
        imagePath
        displayName
        floor
        layout
        paperSize
        scale
        revisedPriceX
        revisedPriceY
        theta
        originX
        originY
        type
        datumTypeIds {
          nodeId
          xPositionDatumType
          yPositionDatumType
          floorDatumType
          layoutDatumType
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDatumType = /* GraphQL */ `
  query GetDatumType($id: ID!) {
    getDatumType(id: $id) {
      id
      nodeTypeId
      index
      name
      labels {
        message
        type
      }
      type
      unit
      digits
      valueMax
      valueMin
      limitHigh
      limitLow
      timeoutInSec
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listDatumTypes = /* GraphQL */ `
  query ListDatumTypes(
    $id: ID
    $filter: ModelDatumTypeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDatumTypes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nodeTypeId
        index
        name
        labels {
          message
          type
        }
        type
        unit
        digits
        valueMax
        valueMin
        limitHigh
        limitLow
        timeoutInSec
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDatumTypesByNodeTypeId = /* GraphQL */ `
  query ListDatumTypesByNodeTypeId(
    $nodeTypeId: ID
    $index: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatumTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatumTypesByNodeTypeId(
      nodeTypeId: $nodeTypeId
      index: $index
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nodeTypeId
        index
        name
        labels {
          message
          type
        }
        type
        unit
        digits
        valueMax
        valueMin
        limitHigh
        limitLow
        timeoutInSec
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDatum = /* GraphQL */ `
  query GetDatum($id: ID!) {
    getDatum(id: $id) {
      id
      nodeId
      typeId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const listData = /* GraphQL */ `
  query ListData(
    $id: ID
    $filter: ModelDatumFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listData(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nodeId
        typeId
        time
        value
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDatumsByNodeIdTypeId = /* GraphQL */ `
  query ListDatumsByNodeIdTypeId(
    $nodeId: ID
    $typeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDatumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatumsByNodeIdTypeId(
      nodeId: $nodeId
      typeId: $typeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nodeId
        typeId
        time
        value
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDatumsByTypeId = /* GraphQL */ `
  query ListDatumsByTypeId(
    $typeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelDatumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDatumsByTypeId(
      typeId: $typeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nodeId
        typeId
        time
        value
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEventlog = /* GraphQL */ `
  query GetEventlog($id: ID!) {
    getEventlog(id: $id) {
      id
      time
      nodeId
      datumId
      datumName
      type
      event
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listEventlogs = /* GraphQL */ `
  query ListEventlogs(
    $filter: ModelEventlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        time
        nodeId
        datumId
        datumName
        type
        event
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEventlogsByNodeId = /* GraphQL */ `
  query ListEventlogsByNodeId(
    $nodeId: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventlogsByNodeId(
      nodeId: $nodeId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        nodeId
        datumId
        datumName
        type
        event
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listEventlogsByDatumId = /* GraphQL */ `
  query ListEventlogsByDatumId(
    $datumId: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEventlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventlogsByDatumId(
      datumId: $datumId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        nodeId
        datumId
        datumName
        type
        event
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNodeSecret = /* GraphQL */ `
  query GetNodeSecret($id: ID!) {
    getNodeSecret(id: $id) {
      id
      camera {
        origin
        username
        password
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNodeSecrets = /* GraphQL */ `
  query ListNodeSecrets(
    $id: ID
    $filter: ModelNodeSecretFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNodeSecrets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        camera {
          origin
          username
          password
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNode = /* GraphQL */ `
  query GetNode($id: ID!) {
    getNode(id: $id) {
      id
      parentId
      typeId
      name
      imagePath
      featureInfos {
        label
        type
      }
      permissions
      treeIndex
      account
      isWarning
      specifications {
        label
        value
      }
      graphic {
        modelPath
        cameraPositionX
        cameraPositionY
        cameraPositionZ
      }
      camera {
        controllable
        statusDatumId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNodes = /* GraphQL */ `
  query ListNodes(
    $id: ID
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNodes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        parentId
        typeId
        name
        imagePath
        featureInfos {
          label
          type
        }
        permissions
        treeIndex
        account
        isWarning
        specifications {
          label
          value
        }
        graphic {
          modelPath
          cameraPositionX
          cameraPositionY
          cameraPositionZ
        }
        camera {
          controllable
          statusDatumId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNodesByParentId = /* GraphQL */ `
  query ListNodesByParentId(
    $parentId: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNodesByParentId(
      parentId: $parentId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        typeId
        name
        imagePath
        featureInfos {
          label
          type
        }
        permissions
        treeIndex
        account
        isWarning
        specifications {
          label
          value
        }
        graphic {
          modelPath
          cameraPositionX
          cameraPositionY
          cameraPositionZ
        }
        camera {
          controllable
          statusDatumId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listNodesByAccount = /* GraphQL */ `
  query ListNodesByAccount(
    $account: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNodesByAccount(
      account: $account
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        parentId
        typeId
        name
        imagePath
        featureInfos {
          label
          type
        }
        permissions
        treeIndex
        account
        isWarning
        specifications {
          label
          value
        }
        graphic {
          modelPath
          cameraPositionX
          cameraPositionY
          cameraPositionZ
        }
        camera {
          controllable
          statusDatumId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOldDatum = /* GraphQL */ `
  query GetOldDatum($id: ID!) {
    getOldDatum(id: $id) {
      id
      datumId
      time
      value
      status
      createdAt
      updatedAt
    }
  }
`;
export const listOldData = /* GraphQL */ `
  query ListOldData(
    $id: ID
    $filter: ModelOldDatumFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOldData(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        datumId
        time
        value
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOldDataByDatumId = /* GraphQL */ `
  query ListOldDataByDatumId(
    $datumId: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOldDatumFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOldDataByDatumId(
      datumId: $datumId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datumId
        time
        value
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOldDatumHourly = /* GraphQL */ `
  query GetOldDatumHourly($id: ID!) {
    getOldDatumHourly(id: $id) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const listOldDatumHourlies = /* GraphQL */ `
  query ListOldDatumHourlies(
    $id: ID
    $filter: ModelOldDatumHourlyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOldDatumHourlies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        datumId
        time
        value {
          max
          mean
          mid
          min
        }
        count
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOldDataHourlyByDatumId = /* GraphQL */ `
  query ListOldDataHourlyByDatumId(
    $datumId: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOldDatumHourlyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOldDataHourlyByDatumId(
      datumId: $datumId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datumId
        time
        value {
          max
          mean
          mid
          min
        }
        count
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOldDatumDaily = /* GraphQL */ `
  query GetOldDatumDaily($id: ID!) {
    getOldDatumDaily(id: $id) {
      id
      datumId
      time
      value {
        max
        mean
        mid
        min
      }
      count
      total
      createdAt
      updatedAt
    }
  }
`;
export const listOldDatumDailies = /* GraphQL */ `
  query ListOldDatumDailies(
    $id: ID
    $filter: ModelOldDatumDailyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOldDatumDailies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        datumId
        time
        value {
          max
          mean
          mid
          min
        }
        count
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOldDataDailyByDatumId = /* GraphQL */ `
  query ListOldDataDailyByDatumId(
    $datumId: ID
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOldDatumDailyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOldDataDailyByDatumId(
      datumId: $datumId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datumId
        time
        value {
          max
          mean
          mid
          min
        }
        count
        total
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOperatingRatio = /* GraphQL */ `
  query GetOperatingRatio($id: ID!) {
    getOperatingRatio(id: $id) {
      id
      nodeId
      operatingDatumId
      failureDatumId
      operatingLabel
      suspensionLabel
      failureLabel
      createdAt
      updatedAt
    }
  }
`;
export const listOperatingRatios = /* GraphQL */ `
  query ListOperatingRatios(
    $id: ID
    $filter: ModelOperatingRatioFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOperatingRatios(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nodeId
        operatingDatumId
        failureDatumId
        operatingLabel
        suspensionLabel
        failureLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOperatingRatioByNodeId = /* GraphQL */ `
  query ListOperatingRatioByNodeId(
    $nodeId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOperatingRatioFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOperatingRatioByNodeId(
      nodeId: $nodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        nodeId
        operatingDatumId
        failureDatumId
        operatingLabel
        suspensionLabel
        failureLabel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
